import React, { useState } from "react";
import FollowersCountIcon from "@/public/icons/FollowersCountIcon.svg";
import HeartIcon from "@/public/icons/HeartIcon.svg";
import EyeIcon from "@/public/icons/EyeIcon.svg";
import LinkIcon from "@/public/icons/LinkIcon.svg";
import DocumentUploadIcon from "@/public/icons/DocumentUploadIcon.svg";
import IconButton from "../Buttons/IconButton";
import VerifiedIcon from "@/public/icons/VerifiedIcon.svg";
import VerifiedTextIcon from "@/public/icons/VerifiedTextIcon.svg";
import AddBookmarkIcon from "@/public/icons/AddBookmarkIcon.svg";
import UnBookmarkIcon from "@/public/icons/UnBookmarkIcon.svg";
import Link from "next/link";
import ImageMultiSrc from "../Main/ImageMultiSrc";
import { useTranslation } from "next-i18next";
import { isLogin } from "@/utils/functions";
import { toast } from "@/utils/toast";
import request from "@/utils/request";

const CompanyCard = (props) => {
  const {
    id=null,
    name = "",
    logo = "",
    bannerImage = "",
    slug = "",
    isVerified = false,
    about = {},
    adsCount = 0,
    followerCount = 0,
    showVerified = false,
  } = props;
  const [isBookmarked,setIsBookmarked]=useState(props.isBookmarked);

  const {t}=useTranslation("common");

  const handleBookmarkClick=(e)=>{
    e.preventDefault();
    if(!isLogin()){
      toast.notify(t("taost_you_should_login_desc"), t("taost_you_should_login_title"),{type:3});
      return
    }
    request(
      {
        url: "/api/company/set-book-mark",
        method: "POST",
      },
      {
        company_id: id,
      },
    ).then(res=>{
      setIsBookmarked(!isBookmarked);
    }).catch(err=>toast.notify("Can't connect to server","Error",{type:2}));
  }

  return (
    <div className={`w-full bg-white rounded-2xl ${showVerified && isVerified?"mt-20":""} relative`}>
      {showVerified && isVerified && (
        <div className="flex absolute rounded-2xl pt-2.5 pl-3.5 rtl:pl-auto rtl:pr-3.5 -z-10 w-full h-24 -top-12 left-0 rtl:left-auto rtl:right-0 bg-gradient-secondary">
          <VerifiedIcon></VerifiedIcon>
          <VerifiedTextIcon className="mt-1"></VerifiedTextIcon>{" "}
          <span className="text-blue-2 text-2xl font-bold italic">
            {t("company_supplier")}
          </span>
        </div>
      )}
      <Link href={"/companies/"+slug} aria-label={"Company "+name}>
        <ImageMultiSrc
          className="p-2 rounded-xl w-full max-h-24 object-cover"
          src={bannerImage}
          alt={name+" banner image"}
        />
      </Link>
      <div className="relative flex justify-end px-2">
        <div className="absolute rounded-full -top-9 left-5 rtl:left-auto rtl:right-5 overflow-hidden shadow-md">
          <ImageMultiSrc
            className="w-14 h-14 object-cover"
            src={logo}
            alt={name}
          />
        </div>
        <span className="inline-flex ml-20  mr-5 rtl:ml-5 rtl:mr-20">
          <FollowersCountIcon className="mr-1 rtl:ml-1 rtl:mr-auto"></FollowersCountIcon>  <span className="hidden sm:flex">{t("company_followers")}:</span>
          {followerCount}
        </span>
        <span className="inline-flex">
          <DocumentUploadIcon className="mr-1 rtl:ml-1 rtl:mr-auto"></DocumentUploadIcon>  <span className="hidden sm:flex">{t("company_ads")}:</span> {adsCount}
        </span>
      </div>
      <div className="flex flex-col px-2 py-2 min-h-[150px]">
        <div className="block">
          <h5 className="text-md font-semibold font-open-sans rtl:font-dana-r">
            {name} <Link href={"/companies/"+slug} aria-label={"Company "+name}><LinkIcon className="inline-block"></LinkIcon></Link>
          </h5>
        </div>
        <p className="font-open-sans rtl:font-dana-r text-sm text-justify text-gray-dark py-2">
          {about && Object.keys(about).length>0 && about}
        </p>
        <div className="mt-auto">
          <IconButton
            link={"/companies/"+slug}
            className="mr-2 rtl:ml-2 rtl:mr-auto"
            type={"PRIMARY"}
            label={t("company_view_profile")}
            icon={EyeIcon}
            color={"blue"}
          ></IconButton>
          <IconButton
            type={isBookmarked?"DARK":"OUTLINE"}
            label={isBookmarked?t("company_unfollow"):t("company_follow")}
            icon={isBookmarked?UnBookmarkIcon:AddBookmarkIcon}
            color={"blue"}
            onClick={handleBookmarkClick}
          ></IconButton>
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
