import React, { Children, cloneElement, useEffect, useState } from "react";
import Arrow from "@/public/icons/Arrow.svg";
import { useRouter } from "next/router";

const ClientPagination = (props) => {
  const { children, className = "", pageSize = 5 } = props;
  const countItems = children ? Children.count(children) : 0;
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState(1);
  const [childItems, setChildItems] = useState(children);

  useEffect(() => {
    setChildItems(
      children.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      )
    );
  }, [currentPage, pageSize]);

  return (
    <>
      <div className={className}>
        {Children.map(childItems, (child, index) => child)}
      </div>
      <nav aria-label="Page navigation" className="text-center mt-12">
        <ul className="inline-flex items-center -space-x-px">
          {currentPage > 1 && (
            <li>
              <a
                className="flex items-center justify-center cursor-pointer mx-2 h-10 w-10 rounded-l-md text-base bg-black text-white group hover:text-blue"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <span className="sr-only">Previous</span>
                <Arrow className="group-hover:[&>path]:stroke-blue"></Arrow>
              </a>
            </li>
          )}
          {Array.from(
            { length: Math.ceil(countItems / pageSize) },
            (x, i) => i + 1
          ).map((num) => (
            <li key={num}>
              <a
                
                className={`flex items-center justify-center cursor-pointer mx-2 h-10 w-10 rounded-md text-base hover:text-blue ${
                  currentPage == num
                    ? "text-white bg-black"
                    : "text-black-base bg-gray-light"
                }`}
                onClick={() => setCurrentPage(num)}
              >
                {num}
              </a>
            </li>
          ))}

          {currentPage < Math.ceil(countItems / pageSize) && (
            <li>
              <a
                
                className="flex items-center justify-center cursor-pointer mx-2 h-10 w-10 rounded-r-md text-base bg-black group text-white hover:text-blue"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <span className="sr-only">Next</span>
                <Arrow
                  className={"group-hover:[&>path]:stroke-blue rotate-180"}
                ></Arrow>
              </a>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default ClientPagination;
